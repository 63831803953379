import request from '@/utils/request'

export const createUser = (params: {
  nickname: string
  role_id: string
  username: string
}) => request({
  url: '/user/create',
  method: 'post',
  data: params
})
// 更新用户账户信息
export const update = (data: {
  avatar:string
  nickname:string
  username:string
}) => request({
  url: '/user/update',
  method: 'post',
  data: data
})

// 更新用户列表用户信息
export const editUser = (data: {
  id: string
  nickname ? : string
  role_id ? : string
}) => request({
  url: '/user/edit',
  method: 'post',
  data: data
})


export const deleteUser = (params: {
    id: string
  }) =>
  request({
    url: '/user/delete',
    method: 'post',
    data: params
  })

export const switchUserState = (params: {
    id: string
  }) =>
  request({
    url: '/user/state/switch',
    method: 'post',
    data: params
  })

export const login = (data: {
  password: string
  username: string
}) => request({
  url: '/user/login',
  method: 'post',
  data: data
})

export const logout = () =>
  request({
    url: '/user/logout',
    method: 'post'
  })

export const register = (data: any) =>
  request({
    url: '/user/register',
    method: 'post',
    data: data
  })


export const getUserInfo = () =>
  request({
    url: '/user/info',
    method: 'post'
  })
// 修改用户账户密码
export const resetPassword = (data: any) => request({
  url: '/user/update/password',
  method: 'post',
  data: data
})
// 修改用户列表中密码
export const editPassword = (data: {
  id: string
}) => request({
  url: '/user/edit/password',
  method: 'post',
  data: data
})

export const addUserByUser = (data: any) => request({
  url: '/user/create_by_user',
  method: 'post',
  data
})

export const getUserList = (data: any) => request({
  url: '/user/list',
  method: 'post',
  data
})

export const getFuzzyList = (data: any) => request({
  url: '/user/fuzzy-search',
  method: 'post',
  data
})
export const getLogList = (data: any) => request({
  url: '/action-log/list',
  method: 'post',
  data
})
export const getEnumActionLog = () => request({
  url: '/enum/action-log',
  method: 'post'
})
export const getEnumSubActionLog = (data: any) => request({
  url: '/enum/sub-action-log',
  method: 'post',
  data
})

export const getUserTableConfig = () => request({
  url: '/column_config/find_one',
  method: 'post'
})

export const setUserTableConfig = (data:any) => request({
  url: '/column_config/modify',
  method: 'post',
  data: data
})

export const getProvinceApi = () => request({
  url: '/industrial/region/province',
  method: 'post'
})
export const getCityApi = (data:any) => request({
  url: '/industrial/region/city',
  method: 'post',
  data: data
})
export const getDistrictApi = (data:any) => request({
  url: '/industrial/region/district',
  method: 'post',
  data: data
})
