






















































import { Component, Vue } from 'vue-property-decorator'
import { update, resetPassword } from '@/api/users'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import UploadImage from '@/components/UploadImage/index.vue'
import { UserModule } from '@/store/modules/user'
import ContainShowImg from '@/common/components/ContainShowImg/index.vue'

import { uploadImage } from '@/api/file'
import md5 from 'js-md5'
@Component({
  name: 'ResetPassword',
  components: {
    UploadImage,
    ContainShowImg
  }
})
export default class extends Vue {
  private resetForm = {
    old_password: '',
    new_password: '',
    repeat_new_password: ''
  }

  private rule = {
    old_password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
    new_password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
    repeat_new_password: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }, {
      validator: this.checkEqual,
      trigger: 'blur'
    }]
  }

  private checkEqual(rule, value, callback) {
    if (value !== this.resetForm.new_password) {
      callback(new Error('两次输入密码不一致'))
    } else {
      callback()
    }
  }

  private update() {
    const params = {
      new_password: md5(this.resetForm.new_password),
      old_password: md5(this.resetForm.old_password)
    }
    const refDom:any = this.$refs.resetForm
    refDom.validate((valid) => {
      if (valid) {
        resetPassword(params).then(res => {
          this.$message.success('重置成功')
          UserModule.LogOut().then(res => {
            this.$router.push('/login')
          })
        })
      }
    })
  }
}

