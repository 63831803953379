var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "track-container" },
    [
      _c("track-map", {
        attrs: {
          "api-get-bei-dou-track-list": _vm.getBeiDouTrackList,
          "api-get-trajectory-list": _vm.getTrajectoryList,
          "api-get-driver-dot": _vm.getDriverDot,
          "api-get-bei-dou-track": _vm.getBeiDouTrack
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }