






























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

export interface IPathInfo {
  deviceType: string
  deviceId: string|number
  pathLength: number
  startTime: number
  endTime: number
  visible: boolean
  color: string
  subWaybillId?:string
  cmpId?:string
  status?:any
}
@Component({
  name: 'MapPannel',
  components: {

  }
})
export default class MapPannel extends Vue {
  @Prop({ default: () => [] }) pathInfos: IPathInfo[]
  @Prop({ default: () => [] }) BDPathInfos: IPathInfo[]
  showBeiDouTrack = false
  actives:string[]=['nobeidou']
  handleChange() {
    console.log(1)
  }

  @Emit('visibleChange')
  handleToggleVisible(pathInfo, index) {
    pathInfo.visible = !pathInfo.visible
    return { index: index, isVisible: pathInfo.visible, cmpId: pathInfo.cmpId }
  }

  @Emit('fetchBeiDouTrack')
  handleFecthBeiDouTrack() {
    this.showBeiDouTrack = true
  }
}

