












































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import { Form as ElForm, Input } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { isGreenSkin, isHaoyunbao } from '@/utils/common'

@Component({
  name: 'Login'
})
export default class extends Vue {
  validateUsername = (rule: any, value: string, callback: Function) => {
    if (!value) {
      callback(new Error('用户名不能空'))
    } else {
      callback()
    }
  };

  validatePassword = (rule: any, value: string, callback: Function) => {
    if (!value) {
      callback(new Error('密码不能为空'))
    } else {
      callback()
    }
  };

  showLoginBox:any = false
  isGreenSkin = isGreenSkin()
  isHaoyunbao = isHaoyunbao()

  loginForm = {
    username: '',
    password: ''
  };

  loginRules = {
    username: [{ validator: this.validateUsername, trigger: 'blur' }],
    password: [{ validator: this.validatePassword, trigger: 'blur' }]
  };

  // passwordType = 'password'
  loading = false;
  // showDialog = false
  // capsTooltip = false
  redirect?: string;
  otherQuery: Dictionary<string> = {};

  @Watch('$route', { immediate: true })
  onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  }

  mounted() {
    const hrefSearch = window.location.search
    if (hrefSearch.indexOf('show=1') > -1) {
      this.showLoginBox = true
    }
    if (this.loginForm.username === '') {
      (this.$refs.username as Input).focus()
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus()
    }
  }

  jumpLogin() {
    const href = window.location.origin.replace('9527', '9529').replace('insight', 'enterprise')
    window.location.href = href + '?show=1'
  }

  handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async(valid: boolean) => {
      if (valid) {
        this.loading = true
        await UserModule.Login(this.loginForm)
        this.$router
          .push({
            path: this.redirect || '/',
            query: this.otherQuery
          })
          .catch(err => console.log(err))
        // Just to simulate the time of the request
        setTimeout(() => {
          this.loading = false
        }, 0.5 * 1000)
      } else {
        return false
      }
    })
  }

  getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {} as Dictionary<string>)
  }
}
