/* eslint-disable camelcase */
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import {
  login,
  logout,
  getUserInfo,
  update,
  getUserTableConfig,
  setUserTableConfig
} from '@/api/users'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import router, { resetRouter } from '@/router'
import { PermissionModule } from './permission'
import { TagsViewModule } from './tags-view'
import store from '@/store'
import md5 from 'js-md5'
import { getRoleConfig, getRole } from '@/api/role'

import to from 'await-to-js'

export interface IUserState {
  token: string
  name: string
  avatar: string
  industrial_park_name: string
  introduction: string
  roles: string[]
  email: string
  roleId: string
  roleMap: any
  roleTree: any
  roleResourceIds: string[]
  userId: string
  roleName: string
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || ''
  public name = ''
  public avatar = ''
  public introduction = ''
  public roles: string[] = []
  public email = ''

  public username = ''
  public industrial_park_name = ''
  public nickname = ''
  public last_login_time = ''
  public phone = ''
  public roleId = ''
  public roleMap: any = {}
  public roleTree: any = {}
  public roleResourceIds: any = []
  public userId = ''
  public roleName = ''
  public tableSetting: any = {}


  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction
  }

  /* 权限Mutation */
  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles
  }

  @Mutation
  private SET_ROLE_ID(role: string) {
    this.roleId = role
  }

  @Mutation
  private SET_ROLE_RESOURCE_IDS(ids: string[]) {
    this.roleResourceIds = ids
  }

  @Mutation
  private SET_ROLE_MAP(map: any) {
    this.roleMap = map
  }

  @Mutation
  private SET_ROLE_TREE(tree: any) {
    this.roleTree = tree
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email
  }

  @Mutation
  private SET_USERNAME(username: string) {
    this.username = username
  }

  @Mutation
  private SET_PARK_NAME(industrial_park_name: string) {
    this.industrial_park_name = industrial_park_name
  }

  @Mutation
  private SET_NICKNAME(nickname: string) {
    this.nickname = nickname
  }

  @Mutation
  private SET_LAST_LOGIN_TIME(last_login_time: string) {
    this.last_login_time = last_login_time
  }

  @Mutation
  private SET_PHONE(phone: string) {
    this.phone = phone
  }

  @Mutation
  private SET_USER_ID(id: string) {
    this.userId = id
  }

  @Mutation
  private SET_ROLE_NAME(name: string) {
    this.roleName = name
  }

  @Mutation
  private SET_TABLE_SETTING(setting: any) {
    this.tableSetting = setting
  }


  @Action
  public async Login(userInfo: { username: string, password: string }) {
    let { username, password } = userInfo

    username = username.trim()
    password = md5(password)

    const [err, res] = await to(login({ username, password }))
    if (!err) {
      const { data } = res
      setToken(data.token)
      this.SET_TOKEN(data.token)
    }
  }


  @Action
  public ResetToken() {
    removeToken()
    this.SET_TOKEN('')
    this.SET_ROLES([])
  }


  @Action({ rawError: true })
  public async GetRoleMap() {
    const { data } = await getRoleConfig()
    const map = {}
    map[data.id] = data
    const getRoleArray = (arry: any) => {
      arry.forEach(c => {
        if (!map[c.id]) {
          map[c.id] = c
        }
        if (c.children && c.children.length) {
          getRoleArray(c.children)
        }
      })
    }
    getRoleArray(data.children)
    this.SET_ROLE_TREE(data)
    this.SET_ROLE_MAP(map)
  }


  @Action({ rawError: true })
  public async GetUserBaseInfo() {
    if (this.token === '') {
      throw Error('GetUserBaseInfo: token is undefined!')
    }
    const { data } = await getUserInfo()
    const { username, nickname, last_login_time, avatar, phone, role, id, industrial_park_name } = data

    this.SET_ROLE_ID(role)
    this.SET_USER_ID(id)
    this.SET_AVATAR(avatar)
    this.SET_PARK_NAME(industrial_park_name)
    this.SET_USERNAME(username)
    this.SET_NICKNAME(nickname)
    this.SET_LAST_LOGIN_TIME(last_login_time)
    this.SET_PHONE(phone)
  }

  @Action({ rawError: true })
  public async GetUserRoleInfo() {
    const { data } = await getRole({ id: this.roleId })
    const resourceIds = data.resource_ids
    const rolename = data.role_name
    const roles = []
    resourceIds.forEach(id => {
      const node = this.roleMap[id]
      if (node) {
        roles.push(node.name)
      }
    })

    if (!roles || roles.length <= 0) {
      throw Error('没有任何权限，请联系管理员')
    }

    this.SET_ROLE_RESOURCE_IDS(resourceIds)
    this.SET_ROLE_NAME(rolename)
    this.SET_ROLES(roles)
  }


  @Action({ rawError: true })
  public async GetUserInfo() {
    await this.GetUserBaseInfo()
    await this.GetRoleMap()
    await this.GetUserRoleInfo()
    await this.GetUserTableSetting()
  }

  @Action
  public async ChangeRoles(role: string) {
    // Dynamically modify permissions
    const token = role + '-token'
    this.SET_TOKEN(token)
    setToken(token)
    await this.GetUserInfo()
    resetRouter()
    // Generate dynamic accessible routes based on roles
    PermissionModule.GenerateRoutes(this.roles)
    // Add generated routes
    router.addRoutes(PermissionModule.dynamicRoutes)
    // Reset visited views and cached views
    TagsViewModule.delAllViews()
  }

  @Action
  public async LogOut(isTimeOut = false) {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!')
    }
    if (!isTimeOut) {
      await logout()
    }
    removeToken()
    resetRouter()

    // Reset visited views and cached views
    TagsViewModule.delAllViews()
    this.SET_TOKEN('')
    this.SET_ROLES([])
  }

  @Action
  public async Update(params: any) {
    await update(params)
    await this.GetUserBaseInfo()
  }

  @Action({ rawError: true })
  public SetTableSettingItem(params: { key: string, value: any }) {
    const setting = this.tableSetting
    setting[params.key] = params.value
    this.SET_TABLE_SETTING(setting)
  }

  @Action({ rawError: true })
  public async GetUserTableSetting() {
    const [err, res] = await to(getUserTableConfig())

    if (!err) {
      try {
        const { data } = res
        if (data.columns) {
          const columnConfig = JSON.parse(data.columns)
          this.SET_TABLE_SETTING(columnConfig)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  @Action
  public async SaveUserTableSetting() {
    const [err, res] = await to(setUserTableConfig({
      columns: JSON.stringify(this.tableSetting)
    }))
    if (!err) {
      return res
    } else {
      throw err
    }
  }
}

export const UserModule = getModule(User)
