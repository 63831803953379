





























import { Vue, Component, Prop } from 'vue-property-decorator'
import KhyMap, { IPath } from '../../components/KhyMap/index.vue'
import MapPannel, { IPathInfo } from './MapPannel.vue'
import MapPannelBeiDou from './MapPannelBeiDou.vue'
import { ITrajectoryData, IGetTrajectoryList } from '../index.d'
import { ColorsMap } from '../../constant'
import _ from 'lodash'
@Component({
  name: 'TrackMap',
  components: {
    KhyMap,
    MapPannel,
    MapPannelBeiDou
  }
})
export default class TrackMap extends Vue {
  @Prop({ default: () => { return () => {} } }) ApiGetTrajectoryList: Function
  @Prop({ default: () => { return () => {} } }) ApiGetDriverDot: Function
  @Prop({ default: () => { return () => {} } }) ApiGetBeiDouTrack: Function
  @Prop({ default: () => { return () => {} } }) ApiGetBeiDouTrackList: Function

  get listQuery(): IGetTrajectoryList {
    return {
      sub_waybill_id: (this.$route.query && this.$route.query.sub_waybill_id) as string || '',
      waybill_id: (this.$route.query && this.$route.query.waybill_id) as string || ''
    }
  }

  pathInfos: IPathInfo[] = []
  fullPaths: IPath[] = []
  paths: IPath[] = []

  BDPaths: IPath[] = []
  BDPathInfos: IPathInfo[] = []


  BDListPathInfos:IPathInfo[] = []
  BDListPaths:IPath[] = []

  districts = []
  dots = {
    load_dots: [],
    receive_dots: [],
    waybill_start_dots: [],
    waybill_end_dots: []
  }

  noTrace = false
  colorOffset = 0;
  get renderDots() {
    return [{
      label: '司机装货',
      points: this.dots.load_dots
    }, {
      label: '司机卸货',
      points: this.dots.receive_dots
    }, {
      label: '运单起点',
      points: this.dots.waybill_start_dots
    }, {
      label: '运单终点',
      points: this.dots.waybill_end_dots
    }]
  }

  isBeiDouLoading = false

  async getList() {
    const { data } = await this.ApiGetTrajectoryList(this.listQuery)
    if (data && data.length) {
      this.noTrace = false
      const { paths, pathInfos } = this.processPathData(data)
      this.fullPaths = this.fullPaths.concat(paths)
      this.pathInfos = pathInfos
      this.paths = [].concat(this.paths.concat(paths))
    } else if (!this.renderDots[0].points.length && !this.renderDots[1].points.length) {
      this.noTrace = true
    }
  }

  async getBeiDouList() {
    const { data } = await this.ApiGetBeiDouTrackList(this.listQuery)
    this.pushBeiDouData(data, 'beidou')
  }

  async pushBeiDouData(data:any, type = '') {
    const { paths, pathInfos } = this.processPathData(data, type)
    this.fullPaths = this.fullPaths.concat(paths)
    this.BDListPathInfos = pathInfos
    this.paths = [].concat(this.paths.concat(paths))
  }

  processPathData(data: ITrajectoryData[] = [], type = '') {
    const paths: IPath[] = []
    const pathInfos: IPathInfo[] = []

    data.forEach((path, idx) => {
      console.log(path.device_id, idx)
      const deviceId = path.device_id.split('(')[0]
      const deviceType = (type === 'beidou' || type === 'beidou_new') ? path.device_id : path.device_id.indexOf('(') > -1 ? path.device_id.split('(')[1].replace(')', '') : '未知'
      const iPath = {
        name: `${deviceType}`,
        path: [],
        visible: true,
        color: (type === 'beidou' || type === 'beidou_new') ? '#4169E1' : (ColorsMap[deviceType] || '#EE9A00'),
        subWaybillId: path.sub_waybill_id,
        cmpId: `${path.device_id}${type ? '_' + type : type}`,
        status: path.status
      }

      if (path.trajectory_detail_view_list.length) {
        path.trajectory_detail_view_list.forEach(item => {
          if (item.longitude && item.latitude && !isNaN(Number(item.longitude)) && !isNaN(Number(item.latitude))) {
            iPath.path.push([item.longitude, item.latitude, item.gather_time, `${path.device_id}${type ? '_' + type : type}`])
          }
        })
        paths.push(iPath)
      }


      pathInfos.push({
        deviceType: deviceType,
        deviceId: deviceId,
        pathLength: iPath.path.length,
        startTime: iPath.path.length ? iPath.path[0][2] : 0,
        endTime: iPath.path.length ? iPath.path[iPath.path.length - 1][2] : 0,
        visible: true,
        color: iPath.color,
        subWaybillId: iPath.subWaybillId || '',
        cmpId: iPath.cmpId,
        status: iPath.status
      })
    })
    this.colorOffset += pathInfos.length
    return {
      paths,
      pathInfos
    }
  }

  async getDriverDot() {
    const { data } = await this.ApiGetDriverDot(this.listQuery)
    // eslint-disable-next-line camelcase
    this.dots.load_dots = (data ? data.load_dots : []).map(item => {
      return [item.longitude, item.latitude, item.timestamp, item.deviceId, item.deviceType]
    })
    this.dots.receive_dots = (data.receive_dots || []).map(item => {
      return [item.longitude, item.latitude, item.timestamp, item.deviceId, item.deviceType]
    })
    this.dots.waybill_start_dots = (data.waybill_start_dots || []).map(item => {
      return [item.longitude, item.latitude, item.timestamp]
    })
    this.dots.waybill_end_dots = (data.waybill_end_dots || []).map(item => {
      return [item.longitude, item.latitude, item.timestamp]
    })
  }

  getDistricts() {
    const sendDistrict:any = this.$route.query && this.$route.query.sendDistrict
    const receiveDistrict:any = this.$route.query && this.$route.query.receiveDistrict
    const sendTime = this.$route.query && this.$route.query.sendTime
    const receiveTime = this.$route.query && this.$route.query.receiveTime
    const sendList = sendDistrict ? sendDistrict.split(',') : []
    const receiveList = receiveDistrict ? receiveDistrict.split(',') : []
    const sendArray:any = []
    const receiveArray:any = []
    sendList.forEach((item) => {
      sendArray.push({
        code: item,
        description: sendTime
      })
    })
    receiveList.forEach((item) => {
      receiveArray.push({
        code: item,
        description: receiveTime
      })
    })
    this.districts = sendArray.concat(receiveArray)
  }

  handleVisibleChange(data) {
    const { isVisible, cmpId } = data
    let path

    this.fullPaths.forEach((p:any) => {
      if (p.cmpId === cmpId) {
        path = p
      }
    })
    if (!path) {
      return
    }
    if (isVisible) {
      this.paths = this.paths.concat(path)
    } else {
      this.paths = _.without(this.paths, path)
    }
  }

  async handleFetchBeiDouTrack(id:string) {
    this.isBeiDouLoading = true
    try {
      const { data } = await this.ApiGetBeiDouTrack({
        id: id
      })
      this.pushBeiDouData([data], 'beidou_new')
    } finally {
      this.isBeiDouLoading = false
    }


    // let { paths, pathInfos } = this.processPathData([data])
    // this.BDPaths = paths
    // this.BDPathInfos = pathInfos
    // this.paths = this.paths.concat(paths)
  }

  async created() {
    this.getDistricts()
    await this.getDriverDot()
    this.getList()
    this.getBeiDouList()
  }
}

