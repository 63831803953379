










import { Vue, Component } from 'vue-property-decorator'
import { getTrajectoryList, getDriverDot, getBeiDouTrack, getBeiDouTrackList } from '@/api/trajectory'
import TrackMap from '@/common/business-components/Track/TrackMap.vue'

@Component({
  name: 'Track',
  components: {
    TrackMap
  }
})
export default class Track extends Vue {
  private getTrajectoryList: Function = getTrajectoryList
  private getDriverDot: Function = getDriverDot
  private getBeiDouTrack: Function = getBeiDouTrack
  private getBeiDouTrackList:Function = getBeiDouTrackList
}

